"use client";

import { useEffect } from "react";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import TagManager from "react-gtm-module";
import { RecoilRoot } from "recoil";

import { MhcLocation } from "graphqlApi/types";

import { SITE_NAME } from "common/constants";
import "theme/print.css";
import "theme/overrideStyles.css";
import "theme/progress-bar.css";
import { theme } from "theme/theme";
import { NextPageWithLayout } from "../common/util/nextHelpers";
import { UrlPathMonitoring } from "common/util/hooks/useDetectUrlUpdate";
import useHotjar from "common/util/hooks/useHotjar";

import { MainLayout } from "layout/MainLayout";
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter";

export interface BasePageProps {
  initializeHotjar?: boolean;
  builtAt?: string;
  locationsForSwitcher: MhcLocation[];
}

export const basePageProps = () => ({
  initializeHotjar: true,
  builtAt: new Date().toString()
});

interface Props extends AppProps<BasePageProps> {
  Component: NextPageWithLayout<BasePageProps>;
}

const MyApp: NextPage<Props> = (props) => {
  const { Component, pageProps } = props;
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ?? ((page) => <MainLayout variant="default">{page}</MainLayout>);

  useHotjar({
    initialize: pageProps?.initializeHotjar,
    id: process.env.HOTJAR_ID,
    sv: process.env.HOTJAR_SV
  });

  const gaTrackingId = process.env.NEXT_PUBLIC_GA4_TRACKING_ID ?? "";
  const gtmId = process.env.NEXT_PUBLIC_GA_TAG_MANAGER_ID ?? "";
  useEffect(() => {
    TagManager && gtmId && TagManager.initialize({ gtmId });
  }, [gtmId]);

  return (
    <AppCacheProvider {...props}>
      {gaTrackingId && (
        <>
          <Script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
          <Script strategy="lazyOnload" id="analytics_gtag">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gaTrackingId}');
            `}
          </Script>
        </>
      )}
      <Head>
        <title>{SITE_NAME}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {pageProps.builtAt && <meta name="builtAt" content={pageProps.builtAt} />}
      </Head>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <CssBaseline />
          <UrlPathMonitoring />
          {getLayout(<Component {...pageProps} />)}
        </RecoilRoot>
      </ThemeProvider>
    </AppCacheProvider>
  );
};

export default MyApp;
